import React, {useState, useEffect, Fragment} from 'react'
//import SkillsModal from './SkillsModal'
import SuccessPopup from '../../common/Popups'
import JwtDecode from 'jwt-decode'
import AddCrewMember from './AddCrewMember'
import EditCrewMember from './EditCrewMember'
import {
    WorkGroupsService,
    PublishSubscribe,
} from '../../../services'
import {
    chDisplayEmployeeRole,
    parseIfJSONString
} from '../../../utils'
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal'

export default function ManageWorkUnit({unit}){

	useEffect(() => {
        console.log("ViewCrewMembers unit.idworkgroup effect being called with idworkgroup:", unit.idworkgroup);
		getExistingEmployees(true);


       PublishSubscribe.quickSubscribe(
            "People/ManageWorkUnit",
            "CH/workgroups",
            handleMessage,
            {idworkgroup: unit.idworkgroup}
        );

	}, [unit.idworkgroup])

	let [existingEmployees, setExistingEmployees] = useState([]);
    let [activeEmployeeIndex, setActiveEmployeeIndex] = useState(false);
    let [employeeToDelete, setEmployeeToDelete] = useState(false);
    let [loading, setLoading] = useState(false);
    let [showAddCrewMember, setShowAddCrewMember] = useState(false);

    let getExistingEmployees = async (useLoading) => {
        if (useLoading) setLoading(true);
        let fetched_existingEmployees = await WorkGroupsService.getEmployees(unit.idworkgroup);
        setExistingEmployees(fetched_existingEmployees);
        if (useLoading) setLoading(false);
    }

    let deleteCrewMember = async() => {
        await WorkGroupsService.removeEmployee({idemployee: employeeToDelete, idworkgroup: unit.idworkgroup});
        setEmployeeToDelete(false);
    }

    let handleMessage = (message) => {
        console.log("Calling ManageWorkUnit handleMessage with message " + message);
        message = parseIfJSONString(message);
        
        if (message.type == "added-employee" || message.type == "removed-employee" || message.type=="edited-employee"){
            getExistingEmployees();
        }
    }

    function formatPhoneNumber(entry = '') {
        if (entry.length < 10) return entry;
        
        const match = entry
            .replace(/\D+/g, '').replace(/^1/, '')
            .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
        const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
        const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
        const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''    
        return `${part1}${part2}${part3}`
    }

    let renderExistingEmployees = ({name, idemployee, phonenumber, workrole, role}, key) => {
        return <div class="px-4 pt-2 mt-2 border-top clearfix">
            <div class="col-12 col-4 p-0 lh-1 mt-2">
                <strong class="text-dark">{name || "No name entered"}</strong>
            </div>
            <div class="col-12 col-md-3 p-0 mr-2 float-left">
                <span class="small-2 fw-100">{idemployee}</span>
            </div>
            <div class="col-12 col-md-2 p-0 float-left ml-md-5">
                {!phonenumber ?
                    <strong class="small-2 fw-100 text-light">No phone entered</strong>
                :
                    <a href={"tel:"+phonenumber}>
                        <strong class="small-2 fw-100">{formatPhoneNumber(phonenumber)}</strong>
                    </a>
                }
            </div> 
            <div class="col-6 col-md-2 p-0 float-left">
                <strong class="small-2 fw-100">{workrole || "No role entered"}</strong>
            </div> 
            <div class="col-6 col-md-2 p-0 float-right float-md-left text-right text-md-left">
                <strong class="small-2 fw-100">{chDisplayEmployeeRole(role)}</strong>
            </div> 
            <div class="col-6 col-md-1 p-0 mx-md-2 float-left text-md-center">
                <a class="small text-light" href="#" onClick={() => setActiveEmployeeIndex(key)}><i class="fa fa-address-card-o fs-16 mr-1" aria-hidden="true"></i></a>
            </div>
            <div class="col-2 col-md-1 p-0 mx-2 mt-0 float-right">
                <a class="small item-remove text-secondary mt-1 float-right" href="#" onClick={() => setEmployeeToDelete(idemployee)}><i class="ti ti-trash"></i></a>
            </div>
        </div>
    }

    return<>
        <div class="col-12 col-md-10 my-5 mx-auto">
            <div class="card border">
                <div class="card-body">
                    <p class="lead fw-700 card-title border-bottom">{unit.name}{/*<a class="small text-secondary mt-1 ml-3" href="#"><i class="ti ti-pencil"></i></a>*/}</p>
                    <p>Invite employees who will follow and participate in daily huddles.
                    {/*<a class="fs-12 fw-600" href="#">Learn more <i class="fa fa-angle-right pl-1"></i></a>*/}
                    </p>


                    <div class="mt-4">
                        <div class="col-12 p-0 mt-5">
                        <label class="strong">Crew leaders and members in this work unit:</label>
                        </div>

                        <div class="clearfix">
                            {/*<div class="col-md-4 px-0 form-group input-group float-left">
                            <input type="text" class="form-control form-control-sm fs-13" placeholder="Search profiles" />
                            <div class="input-group-append">
                            <button class="btn btn-secondary px-3" type="button"><i class="fa fa-search fs-14 fw-100" aria-hidden="true"></i></button>
                            </div>
                            </div>*/}
                            <div class="float-right">
                                <button class="btn btn-circle btn-sm btn-primary mb-4 mb-lg-0 ml-lg-4 px-4 pt-1" onClick={() => setShowAddCrewMember(true)}><i class="fa fa-plus fw-400"></i></button>
                            </div>
                        </div>

                        {!_.isEmpty(existingEmployees) ? existingEmployees.map(renderExistingEmployees) : null}

                    </div>
                </div>
            </div>
        </div>

        <SuccessPopup
            trigger={employeeToDelete !== false}
            popupId="delete-crewmember-popup"
            style="warning"
            useButton={true}
            buttonLabel="Yes"
            title={"Delete Crew Member"}
            message={"Really delete crew member? This cannot be undone"}
            callback={deleteCrewMember}
        />

        <Modal
            show={showAddCrewMember == true}
            onHide={() => setShowAddCrewMember(false)}
            size="xl"
        >
            <AddCrewMember 
                unit={unit}
                closeModal={() => setShowAddCrewMember(false)}
            />
        </Modal>

        <Modal
            show={activeEmployeeIndex !== false}
            onHide={() => setActiveEmployeeIndex(false)}
            size="xl"
        >
            <EditCrewMember 
                passedEmployee={existingEmployees[activeEmployeeIndex]}
                unit={unit}
                closeModal={() => setActiveEmployeeIndex(false)}
            />
        </Modal>

    </>
}