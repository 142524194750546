import React, { Component } from 'react';

class Combobox extends Component {
  constructor(props) {
    super(props);

    this.optionsContainer = null;

    this.setOptionsContainerRef = element => {
      this.optionsContainer = element;
      window.addEventListener('wheel', e => {
        if(this.optionsContainer){
          const {left, right, top, bottom} = this.optionsContainer.getBoundingClientRect()
          if(e.clientX < left || e.clientX > right || e.clientY < top || e.clientY > bottom){
            this.setState({
              renderItems: false,
            })
          }
        }
      })
      window.addEventListener('mousedown', e => {
        if(this.optionsContainer){
          const {left, right, top, bottom} = this.optionsContainer.getBoundingClientRect()
          if(e.clientX < left || e.clientX > right || e.clientY < top || e.clientY > bottom){
            this.setState({
              renderItems: false,
            })
          }
        }
      })
    };
    this.inputElement = null;

    this.setInputElementRef = element => {
      this.inputElement = element;
    };

    this.setAddOptionElementRef = element => {
      console.log(element)
      this.setState({addOptionElement: element});
    };

    let val = this.props.value || '';
    if(this.props.items) this.props.items.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    let options = this.props.items && this.props.items.length > 0 ? this.props.items.filter(item => this.props.filterOptions(item, val)) : []
    this.state = {
      value: val,
      renderItems: false,
      options: options,
      shownOptions: options.slice(0, 150),
      page: 0,
      itemHeight: 20,
      showAddOption: this.props.showAddOption,
      addOptionElement: null,
    };

  }

  componentWillReceiveProps(nextProps){
    if(nextProps.items) nextProps.items.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    if(nextProps.value != this.props.value){
      let val = nextProps.value || '';
      let options = nextProps.items.filter(item => nextProps.filterOptions(item, val))
      this.setState({
        value: val,
        renderItems: false,
        shownOptions: options.slice(0, 150),
        page: 0,
      })
    }
    if(nextProps.items !== this.props.items){
      let val = this.props.value || '';
      let options = nextProps.items && nextProps.items.length > 0 ? nextProps.items.filter(item => nextProps.filterOptions(item, val)) : []
      this.setState({
        options,
        shownOptions: options.slice(0, 150),
      })
    }
    if(nextProps.showAddOption !== this.props.showAddOption){
      this.setState({showAddOption: nextProps.showAddOption});
    }
  }
  renderItem(item){
    function highlight(e){
      for(let i in this.props.hoverStyle)
        e.target.style[i] = this.props.hoverStyle[i];
    }
    function dehighlight(e){
      for(let i in this.props.normalStyle)
        e.target.style[i] = this.props.normalStyle[i];
    }
    return (
      <div
        key={item[this.props.identifier]}
        value={item[this.props.identifier]}
        onMouseDown={this.onSelect.bind(this)}
        onMouseEnter={highlight.bind(this)}
        onMouseLeave={dehighlight.bind(this)}
        style={this.props.normalStyle}
      >
        {this.props.renderItem(item)}
      </div>)
  }

  onFocus(e){
    this.setState({
      renderItems: true
    })
  }

  onBlur(e){
    if(this.optionsContainer){
      this.optionsContainer.scrollTop = 0;
    }
    this.setState({
      renderItems: false,
      page: 0,
      shownOptions: this.state.options.slice(0, 150),
    })
  }
  
  onChange(e){
    let newOptions = this.props.items.filter(item => this.props.filterOptions(item, e.target.value));
    this.setState({
      value: e.target.value,
      options: newOptions,
      shownOptions: newOptions.slice(0, 150),
      page: 0,
    });
    if(this.optionsContainer){
      this.optionsContainer.scrollTop = 0;
    }
  }
  
  onSelect(e){
    let newOptions = this.props.items.filter(item => this.props.filterOptions(item, e.target.innerHTML));
    this.setState({
      value: e.target.innerHTML,
      options: newOptions,
      shownOptions: newOptions.slice(0, 150)
    });
    if(this.optionsContainer){
      this.optionsContainer.scrollTop = 0;
    }
    this.props.onSelect(this.props.items.find(item => {
      return item[this.props.identifier] == e.target.getAttribute('value')
    }))
  }
  
  renderItems(){
    if(this.state.renderItems){
      const {left, bottom, width} = this.inputElement.getBoundingClientRect();
      let divStyle = {
        maxHeight: 300,
        overflow: 'scroll',
        scrollbarWidth: 'none',
        backgroundColor: 'white',
        zIndex: 100,
        position: 'absolute',
        width: this.props.width || this.inputElement ? this.inputElement.offsetWidth : 200,
        //left: left,
        //top: bottom,
        borderStyle: 'inset',
        paddingTop: 5,
        paddingLeft: 3,
      }
      function onScroll(e){
        if(e.target.scrollTop > (100 + 50*this.state.page)*this.state.itemHeight && this.state.page < Math.ceil(this.state.options.length / 50) - 2){
          let newPage = Math.ceil(e.target.scrollTop / 50 / this.state.itemHeight) - 2;
          this.setState({
            page: newPage,
            shownOptions: this.state.options.slice(newPage*50, 150 + newPage*50)
          });
        }
        if(e.target.scrollTop < (50 + 50*this.state.page)*this.state.itemHeight && this.state.page > 0){
          let newPage = Math.ceil(e.target.scrollTop / 50 / this.state.itemHeight) - 2;
          newPage = newPage > 0 ? newPage : 0;
          this.setState({
            page: newPage,
            shownOptions: this.state.options.slice(newPage*50, 150 + newPage*50)
          });
        }
      }
      console.log(this.state.addOptionElement && this.state.addOptionElement.offsetHeight)
      return(
        <div
          style={{...this.props.itemsStyle, ...divStyle, paddingTop: this.state.addOptionElement ? this.state.addOptionElement.offsetHeight : '30px'}}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          onScroll={onScroll.bind(this)}
          ref={this.setOptionsContainerRef}
        >
          {
            this.state.showAddOption
            ?
            <div style={{borderBottom: '2px solid gray', padding: '0.4em 0.2em', position: 'absolute', width: '100%', top: 0}} ref={this.setAddOptionElementRef}>
              <label>{this.state.value}</label>
              <button style={{float: 'right'}} onMouseDown={() => this.props.onAddElement(this.state.value)}>Add</button>
            </div>
            :
            null
          }
          <div style={{height: this.state.page*1000}}></div>
          {this.state.shownOptions.map(this.renderItem.bind(this))}
          {this.state.shownOptions.length == 0 ? <div style={{color: 'gray'}}>(No Options)</div> : null}
          <div style={{height: this.state.options.length > 150 ? Math.ceil(this.state.options.length / 50)*1000 - 3000 - this.state.page*1000 : 0}}></div>
        </div>
      )
    }else{
      return null
    }
  }
  render() {
    return (<div style={{position: 'relative', width: '100%'}}>
      <div
        style={{position: 'absoulte'}}
        onFocus={this.onFocus.bind(this)}
        onBlur={this.onBlur.bind(this)}
      >
        <input
          type="text"
          value={this.state.value}
          onChange={this.onChange.bind(this)}
          style={this.props.style}
          ref={this.setInputElementRef}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          className={this.props.className}
          onClick={e => {
            console.log(e.target.select, this.state)
            e.target.select()
            /* this.setState({value: ""})
            this.onChange.bind(this)({target:{value:""}}) */
            if(!this.state.renderItems){
              console.log(this.state)
              console.log(this.props)
              this.setState({
                renderItems: true,
                page: 0,
                shownOptions: this.state.options.slice(0, 150),
              })
            }
          }}
        />
        {this.renderItems()}
      </div></div>
    );
  }
}

export default Combobox;
